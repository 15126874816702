import React, { useState, useEffect } from "react";

const ClientInfo = () => {
  const [userOS, setUserOS] = useState("");
  const [userBrowser, setUserBrowser] = useState("");
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    // Get user's operating system
    const userOS = navigator.platform;
    setUserOS(userOS);

    // // Get user's browser
    // const userBrowser = navigator.userAgent;
    // setUserBrowser(userBrowser);

    // Get window size
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Initial window size
    handleResize();

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="w-full md:w-full mx-0 md:mx-auto flex p-2 items-center">
      <p className="text-xs sm:text-xxs">
        Operating System: {userOS} <br></br>
        Window Size: {windowSize.width}x{windowSize.height}
      </p>
      {/* <p className="text-xs">
        Window Size: {windowSize.width}x{windowSize.height}
      </p> */}
    </div>
  );
};

export default ClientInfo;

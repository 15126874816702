import React, { useState, useEffect } from "react";
import "../styles.css";

const TimeDisplay = () => {
  const [time, setTime] = useState("");

  useEffect(() => {
    const fetchTime = async () => {
      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${API_BASE_URL}/api/time`);
        const data = await response.text();
        console.log(data);
        setTime(data);
      } catch (error) {
        console.error("Error fetching time:", error);
      }
    };
    fetchTime();
    const intervalId = setInterval(fetchTime, 1000); // Update time every second
    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  return (
    <div className="w-full mx-0 p-4 text-left">
      <p className="text-xs md:text-lg">
        Based in Kuala Lumpur, MY → {time} {/* Add the blinking red dot */}
        <span className="blinking-dot"> • </span>
      </p>
      {/* Social Media Links */}
      <div className="mt-4">
        <a
          href="mailto:donirfan.work@gmail.com"
          className="mr-2"
          title="Send an email to donirfan.work@gmail.com"
        >
          <i className="fa fa-envelope md:fa-lg"></i>
        </a>
        <a
          href="http://www.twitter.com/d0nirfan"
          className="mx-2"
          title="Find me on X.com :)"
          target="_blank"
        >
          <i className="fab fa-x-twitter md:fa-lg"></i>
        </a>
        <a
          href="https://www.instagram.com/don.1rfan/"
          className="mx-2"
          title="Find me on ig @don.1rfan"
          target="_blank"
        >
          <i className="fab fa-instagram md:fa-lg"></i>
        </a>
        <a
          href="https://github.com/ddooonn1"
          className="mx-2"
          title="check my github stuff"
          target="_blank"
        >
          <i className="fab fa-github sm:fa-lg"></i>
        </a>
      </div>
    </div>
  );
};

export default TimeDisplay;

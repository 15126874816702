import React, { useState, useEffect } from "react";

const Profile = () => {
  const [profile, setProfile] = useState({});

  useEffect(() => {
    // Use the environment variable to determine the base URL
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    fetch(`${API_BASE_URL}/api/profile`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // Make sure to return the parsed JSON
      })
      .then((data) => {
        setProfile(data);
      })
      .catch((error) => console.error("Error fetching profile:", error));
  }, []); // Dependency array left empty to run only once after the initial render

  return (
    <div className=" flex w-full mx-0 p-4 text-left border-b border-black">
      <p className="text-2xl md:text-4xl mobile-flex-start">
        Hi, I'm{" "}
        <span className="font-bold">
          {`${profile.firstName} ${profile.lastName}`}.{" "}
        </span>
        I consider myself as {profile.bio} whose practice spans across&nbsp;
        <span className="font-bold">
          Software Engineering, Web Development, Data Science/Analyst & Digital
          Marketing.
        </span>{" "}
        Currently at{" "}
        <a
          href="https://cardiffskateboardclub.com/"
          className="underline skateboard-cursor"
          target="_blank"
        >
          {profile.currently}
        </a>{" "}
        serving as {profile.jobTitle}.<br></br>
        <br></br>
        <span className="text-2xl md:text-4xl">
          &#x1F44B; Send me a message for collaboration!
        </span>
      </p>
    </div>
  );
};

export default Profile;

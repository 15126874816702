import React from "react";

const ViewCV = () => {
  const handleViewClick = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    fetch(`${API_BASE_URL}/CV`, {
      method: "GET",
      responseType: "blob",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);

        // Open the PDF in a new tab
        const newTab = window.open();
        newTab.document.write(
          `<iframe width='100%' height='100%' src='${url}'></iframe>`
        );

        // Clean up the object URL when the new tab is closed
        newTab.onbeforeunload = () => {
          URL.revokeObjectURL(url);
        };
      })
      .catch((error) => {
        console.error("Error viewing PDF file:", error);
      });
  };

  const handleDownloadClick = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    fetch(`${API_BASE_URL}/CV`, {
      method: "GET",
      responseType: "blob",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "DonIrfanRashid-Resume.pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading PDF file:", error);
      });
  };

  return (
    <div className="fixed bottom-0 left-0 mb-4 ml-4">
      <span>CV / </span>
      <a
        href={`${process.env.REACT_APP_API_BASE_URL}/CV`}
        className="text-m underline"
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleViewClick}
      >
        View
      </a>{" "}
      <span>—</span>{" "}
      <button className="text-m underline" onClick={handleDownloadClick}>
        Download
      </button>
    </div>
  );
};

export default ViewCV;

import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import "tailwindcss/tailwind.css";
import "./styles.css";
import ReactFullpage from "@fullpage/react-fullpage";

import TimeDisplay from "./Components/TimeDisplay";
import Profile from "./Components/Profile";
import ViewCV from "./Components/ViewCV";
import ClientInfo from "./Components/ClientInfo";
import Skateboarding from "./Components/Skateboarding";

// Loader component covering the full page
const Loader = ({ loadingProgress }) => (
  <div className="preloader absolute inset-0 flex items-center justify-center bg-black">
    <div className="preloader-inner">
      <div className="preloader-progress-wrapper">
        <div
          className="preloader-progress"
          style={{ width: `${loadingProgress}%` }}
        >
          <p className="text-lg md:text-4xl">
            <span className="block">Loading...</span>
            <span>{loadingProgress}%</span>
          </p>
        </div>
      </div>
    </div>
  </div>
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingProgress: 0,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.loadingInterval = setInterval(() => {
      if (this.state.loadingProgress < 100) {
        this.setState((prevState) => ({
          loadingProgress: prevState.loadingProgress + 1,
        }));
      } else {
        clearInterval(this.loadingInterval);
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 500);
      }
    }, 20);
    document.title = "don irfan — independent developer";
  }

  componentWillUnmount() {
    clearInterval(this.loadingInterval);
    document.title = "don irfan";
  }

  render() {
    const { loadingProgress, isLoading } = this.state;

    return (
      <div>
        {isLoading ? (
          <Loader loadingProgress={loadingProgress} />
        ) : (
          <CSSTransition
            in={!isLoading}
            timeout={1000}
            classNames="content"
            unmountOnExit
          >
            <ReactFullpage
              render={({ state, fullpageApi }) => (
                <ReactFullpage.Wrapper>
                  <div
                    id="navbar"
                    className="fade-in fixed top-0 left-0 w-full z-10 flex px-1"
                  >
                    <ClientInfo />
                    {/* <ThemeToggle /> */}
                  </div>

                  <div className="section fade-in">
                    {/* Left Half */}
                    <div className="w-full md:w-4/5 mx-0 md:mx-auto p-4 block justify-end items-center">
                      <Profile />
                      <TimeDisplay />
                    </div>
                    <ViewCV />

                    {/* Right Half */}
                    {/* <div class="w-1/2 hidden md:block">
                      <Skateboarding />
                    </div> */}
                  </div>

                  {/* Additional sections can be added here */}
                </ReactFullpage.Wrapper>
              )}
            />
          </CSSTransition>
        )}
      </div>
    );
  }
}

export default App;
